<table mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Symbol </th>
        <td mat-cell *matCellDef="let element"> {{ element.symbol }} </td>
    </ng-container>
    <ng-container matColumnDef="price_target">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Price Target </th>
        <td mat-cell *matCellDef="let element"> {{ element.price_target }} </td>
    </ng-container>
    <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let element"> {{ element.timestamp }} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
            <!-- <button mat-raised-button color="primary" (click)="editPriceTarget(element)">Edit</button> -->
            <button mat-icon-button color="warn" (click)="deletePriceTarget(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
