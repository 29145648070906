<div class="input-form mx-auto">
    <mat-form-field class="input-form-field">
        <mat-label class="input-field">Search</mat-label>
        <input  matInput placeholder="Company search" [(ngModel)]="symbol" [disabled]="_disabled" (keydown.enter)="onClick()">
        <mat-icon matPrefix>search</mat-icon>

        <mat-select class="search-bar-select mat-select-arrow" #singleSelect (selectionChange)="onSelectionChange($event)">
            <mat-option *ngFor="let item of search_list" [value]="item.symbol">
                {{item.symbol}}: {{item.company_name}}
            </mat-option>
        </mat-select>
        <button matSuffix class="action-button" mat-raised-button color="primary" (click)="onClick()" [disabled]="_disabled">
            <mat-icon>search</mat-icon>
            Analyze
        </button>
        <button matSuffix class="compare-button d-none d-md-inline" mat-raised-button color="primary" (click)="goToComparison()" matTooltip="Compare with...">
            <mat-icon style="margin-left: auto; margin-right: auto;">compare_arrows</mat-icon>
        </button>
    </mat-form-field>
    <div>
        <button matSuffix class="compare-button d-md-none" mat-icon-button color="primary" (click)="goToComparison()" matTooltip="Compare with...">
            <mat-icon style="margin-left: auto; margin-right: auto;">compare_arrows</mat-icon>
        </button>
        <button matSuffix class="d-md-none" mat-icon-button color="primary" matTooltip="Random" (click)="onClickRandom()" [disabled]="_disabled">
            <mat-icon>shuffle</mat-icon>
        </button>
    </div>
    <button matSuffix class="d-none d-md-inline" mat-icon-button color="primary" matTooltip="Random" (click)="onClickRandom()" [disabled]="_disabled">
        <mat-icon>shuffle</mat-icon>
    </button>
</div>
