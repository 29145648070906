
<mat-card class="my-3">
    <app-premium-feature-icon class="top-right-corner"></app-premium-feature-icon> 
    <mat-card-header>
        <mat-card-subtitle>Know if it is the right time to buy</mat-card-subtitle>
        <mat-card-title>Technical Timing Analysis</mat-card-title>
    </mat-card-header>
    <mat-accordion displayMode="flat">
        <mat-expansion-panel [expanded]="expanded" class="mat-elevation-z0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Is it the right time to buy?
                </mat-panel-title>
                <mat-panel-description [class.blur]="!haveAccess()">
                    <div *ngIf="haveAccess() && this.tapScoreState.sentiment == 'no_buy'" class="indicative_text" [class.blur]="!haveAccess()">
                        We recommend to <b>wait</b> before buying.
                    </div>
                    <div *ngIf="haveAccess() && this.tapScoreState.sentiment == 'buy'" class="indicative_text" [class.blur]="!haveAccess()">
                        It seems to be the right time to <b>buy</b>.
                    </div>
                    <div *ngIf="haveAccess() && this.tapScoreState.sentiment == 'no_decision'" class="indicative_text" [class.blur]="!haveAccess()">
                        We recommand waiting for clearer signals.
                    </div>
                    <div *ngIf="!haveAccess()" class="indicative_text" [class.blur]="!haveAccess()">
                        We recommend to go <b>premium</b> instead of trying to hack the system
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="!haveAccess()" [class.div_blur]="!haveAccess()"></div>
            <div *ngIf="!haveAccess()" [class.div_blur_text]="!haveAccess()">
                Go Premium to access this content
                <app-go-premium-button color="accent">Go Premium</app-go-premium-button>
            </div>

            <mat-list>
                <mat-list-item *ngFor="let pillar_data of this.technical_pillars_results_augmented" [class.blur]="!haveAccess()">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">{{pillar_data.short_description}}</div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <app-simple-progress-bar
                            [color]="pillar_data.score_color" 
                            [percentage]="pillar_data.score_percentage">
                            </app-simple-progress-bar>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h3 style="font-weight: 500;">Technical Analysis result</h3>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <app-simple-progress-bar
                    [color]="tapScoreState.global_score_color" 
                    [percentage]="tapScoreState.global_score_percentage">
                    </app-simple-progress-bar>
                </div>
                <div *ngIf="haveAccess() && this.tapScoreState.sentiment == 'no_buy'" class="indicative_text" [class.blur]="!haveAccess()">
                    <h3>Based on the Technical Analysis Pillars, we recommend to <b>wait</b> before buying.</h3>
                </div>
                <div *ngIf="haveAccess() && this.tapScoreState.sentiment == 'buy'" class="indicative_text" [class.blur]="!haveAccess()">
                    <h3>Based on the Technical Analysis Pillars, it seems to be the right time to <b>buy</b>.</h3>
                </div>
                <div *ngIf="haveAccess() && this.tapScoreState.sentiment == 'no_decision'" class="indicative_text" [class.blur]="!haveAccess()">
                    <h3>Based on the Technical Analysis Pillars, we recommand waiting for clearer signals.</h3>
                </div>
                <div *ngIf="!haveAccess()" class="indicative_text" [class.blur]="!haveAccess()">
                    <h3>Based on the Technical Analysis Pillars, we recommend to go <b>premium</b> instead of trying to hack the system</h3>
                </div>
            </div>

            <app-timeplot
            [class.blur]="!haveAccess()" 
            [chart_title]="['Stock Price', 'SMA ST', 'SMA LT', 'RSI']" 
            [time_values]="this.stockPriceData.labels" 
            [values]="[
                this.stockPriceData.values,
                this.technical_data_for_pillars?.smaShortTermData,
                this.technical_data_for_pillars?.smaLongTermData,
                this.technical_data_for_pillars?.rsiData]" 
            [show_points]="false"></app-timeplot>
        </mat-expansion-panel>
    </mat-accordion>
</mat-card>

