import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SymbolData } from '../components/symbol-list/symbol-list.component';
import { getLocalStorageItem, setLocalStorageItem } from '../core/local-storage-manager';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { PillarData } from '../core/pillars.service';
import { NavigationService } from '../navigation.service';
import { getPorfolioIdNameList } from '../portfolio-page/portfolio_data';
import { arrayRange, convert_int_to_ext_symbol, getColorFromScore, leastSquares1d } from '../core/helpers';
import { NeoTradrUser, UserService } from '../core/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

interface FavoriteData {
  symbol: string,
  score?: number,
  company_name?: string,
  stock_price: number,
  short_term_trend?: number // Indicate if price is up (greated than 0) or not
  // timestamp: Date
}

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  isLoading: boolean = false
  isLoadingFavorite: boolean = false
  isSmallScreen: boolean = false
  isLoggedIn: boolean = false
  symbol: string = "AAPL"
  symbolHistory: {[key: string]: SymbolData} = {}
  favoriteList: Array<string>|null = []
  ntUser: NeoTradrUser|null = null

  links: Array<{name: string, page: string}> = [
    {name: 'Best ranked companies', page: 'discover'},
    ...getPorfolioIdNameList().map(x => { 
      return {name: x.portfolio_name, page: `portfolio/${x.id}`} 
    })
  ]

  @Input() goOnClick?: boolean = false
  displayedColumns = ['favicon', 'symbol','company_name','stock_price', 'stock_price_trend','vip_score', 'action']
  dataSource: MatTableDataSource<FavoriteData> = new MatTableDataSource()
  selectedRow: FavoriteData|null = null
  @ViewChild(MatSort) sort!: MatSort; // Setting the sort on the table
  @ViewChild(MatPaginator) paginator!: MatPaginator; // Paginator 

  getColorFromScore = getColorFromScore

  constructor(
    private loginService: LoginService,
    private router: Router,
    private navigation: NavigationService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.loadSymbolHistory()
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn
      this.refreshFavoriteList()
    })
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onSymbolInput(symbol: string): void {
    this.symbol = symbol
    console.log('OnSymbolInput:', symbol)
    
    this.setSymbolToHistory(symbol, 0, [])
    this.goToCompanyPage(symbol)
  }

  goToCompanyPage(symbol: string): void {
    this.router.navigate(['company', convert_int_to_ext_symbol(symbol)])
  }

  goToBusinessNews(): void {
    this.router.navigate(['news'])
  }

  goToDiscoverPage(): void {
    this.router.navigate(['discover'])
  }

  goTo(path: string): void {
    this.router.navigate([path])
  }

  loadSymbolHistory(): void {
    let savedHistory:({[key: string]: SymbolData})|null = getLocalStorageItem('symbolhistory')
    if(!savedHistory){
      savedHistory = {}
    }
    this.symbolHistory = savedHistory
  }

  setSymbolToHistory(symbol: string, score: number, pillars_data: Array<PillarData>): void {
    let savedHistory:({[key: string]: SymbolData})|null = getLocalStorageItem('symbolhistory')
    if(!savedHistory){
      savedHistory = {}
    }
    savedHistory[symbol] = {
      symbol: symbol,
      timestamp: new Date(),
      score: score,
      pillars_data: pillars_data
    }
    setLocalStorageItem('symbolhistory', savedHistory, 60*60*24*365*15)
  }

  back(): void {
    this.navigation.back()
  }

  async refreshFavoriteList() {
    this.isLoadingFavorite = true
    const idToken = await this.loginService.getIdToken()
    this.userService.getFavoriteDetailsList(idToken).subscribe(favDetails => {
      if(favDetails){
        this.dataSource = new MatTableDataSource<FavoriteData>(favDetails.map(favorite => {
          const sortedPrice = favorite.stock_price.sort(
            (a, b) => new Date(a.data_date).getTime() < new Date(b.data_date).getTime() ? -1 : 1)
          const [growth, b] = leastSquares1d(arrayRange(0, sortedPrice.length, 1), sortedPrice.map(x => x.value))
          return {
            symbol: favorite.symbol,
            score: favorite.score,
            company_name: favorite.company_name,
            stock_price: sortedPrice[sortedPrice.length-1].value,
            short_term_trend: growth
          }
        }))
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      } else {
        this.favoriteList = []
      }
      this.isLoadingFavorite = false
    })
    this.userService.getUser(idToken).subscribe(ntUser => {
      this.ntUser = ntUser
    })
  }

  onRowSelected(row: FavoriteData) {
    console.log('slected row:', row)
    this.selectedRow = row
    // this.rowSelectedEvent.emit(this.selectedRow)
    if(this.goOnClick){
      this.goToCompanyPage(row.symbol)
    }
  }

  getWelcomeMessage(): string|null {
    if(this.ntUser == null || !this.ntUser.name){
      return null
    }
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    if (currentHour < 12) {
        return `Good morning ${this.ntUser.name}!`;
    } else if (currentHour >= 12 && currentHour < 18) {
        return `Good afternoon ${this.ntUser.name}!`;
    } else {
        return `Good evening ${this.ntUser.name}!`;
    }
  }
}
