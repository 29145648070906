<app-counter-banner></app-counter-banner>
<div class="header-sections pb-0">
    <section>
        <mat-toolbar color="secondary" style="background-color: transparent;">
            <mat-toolbar-row>
                <button style="margin-right: auto;" mat-button (click)="back()">
                    <mat-icon>arrow_back_ios</mat-icon>
                    Back
                </button>
    
                <app-search-bar class="text-center d-none d-sm-block" style="margin-left: auto; margin-right: auto; margin-top: 60px;" [symbol]="this.companySymbol!"></app-search-bar>
    
                <button mat-icon-button [matMenuTriggerFor]="companyMore" style="right: 0; margin-left: auto;">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #companyMore="matMenu">
                    <button mat-menu-item (click)="goToComparison()">
                        <mat-icon>compare_arrows</mat-icon>
                        Compare with...
                    </button>
                </mat-menu>
            </mat-toolbar-row>
        </mat-toolbar>
    </section>
    <section class="d-sm-none">
        <div class="container">
            <div class="row py-0 mt-0 justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 text-center">
                    <app-search-bar [symbol]="this.companySymbol!"></app-search-bar>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row py-0 my-0 justify-content-center">
                <div class="col-xl-6 col-lg-6 col-md-8 col-sm-8 col-xs-8">
                    <div class="mt-4 row">
                        <div class="col-1">
                            <div>
                                <div [matTooltip]="userIsLogged() ? 'Add to favorite' : 'You need to be logged in'" style="width: 48px; height: 48px;">
                                    <button [matTooltip]="userIsLogged() ? 'Add to favorite' : 'You need to be logged in'" mat-icon-button (click)="swapFavorite()" [disabled]="!userIsLogged()">
                                        <mat-icon *ngIf="isFavorite()" class="favorite-icon">star</mat-icon>
                                        <mat-icon *ngIf="!isFavorite()">star_border</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-11">
                            <div>
                                <div class="mt-2">
                                    <div class="nt-primary" style="font-family: Inter; font-size: 44px; font-weight: 800;">
                                        {{this.companySymbol}}
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <div class="mb-0" style="font-family: Inter; font-size: 18px; color: rgb(107, 114, 128); line-height: 1.4;">
                                        {{this.companyName}} 
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isLoading">
                                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <div class="justify-content-center text-center mt-4">
                        <div>
                            <span class="mx-auto highlight-title" matTooltip="VIP Score computed from Value Investing Pillars">
                                Global Momentum Score
                            </span>
                            <div class="row">
                                <div class="col-10">
                                    <div *ngIf="this.tapScoreState.global_score_percentage" [style]="
                                        'background-color:'+getColorFromScore(this.tapScoreState.global_score_percentage)
                                        + '; border-radius: 30px; padding: 10px;'">
                                            <b>{{this.tapScoreState.global_score_percentage | number:'1.0-0'}}</b>/100
                                    </div>
                                </div>
                                <div class="col-2">
                                    <button mat-icon-button (click)="scrollToSection('valuation-section')">
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <span *ngIf="!this.tapScoreState.global_score_percentage">-</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div class="container">
    <div class="row justify-content-center">
        <div class="col-xl-8 col-lg-8 col-md-12 col-xs-12">
            <mat-card class="my-2">
                <mat-card-header>
                    <mat-card-title>
                        Overall timing analysis
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <!-- <mat-panel-description [class.blur]="!userHasPremium()"> -->
                    <div *ngIf="userHasPremium() && this.tapScoreState.sentiment == 'no_buy'" class="indicative_text" [class.blur]="!userHasPremium()">
                        <div class="mx-auto text-center">
                            The analysis suggest that it is the right time to
                            <h1>Wait</h1>
                        </div>
                    </div>
                    <div *ngIf="userHasPremium() && this.tapScoreState.sentiment == 'buy'" class="indicative_text" [class.blur]="!userHasPremium()">
                        <div class="mx-auto text-center">
                            The analysis suggest that it is the right time to
                            <h1>Buy</h1>
                        </div>
                    </div>
                    <div *ngIf="userHasPremium() && this.tapScoreState.sentiment == 'no_decision'" class="indicative_text" [class.blur]="!userHasPremium()">
                        <div class="mx-auto text-center">
                            Due to unclear results, we suggest to 
                            <h1>Wait</h1>
                        </div>
                    </div>
                    <div *ngIf="!userHasPremium()" class="indicative_text" [class.blur]="!userHasPremium()">
                        We recommend to go <b>premium</b> instead of trying to hack the system
                    </div>
                    <!-- </mat-panel-description> -->
                </mat-card-content>
            </mat-card>
            
            <mat-card class="my-2">
                <mat-card-header>
                    <mat-card-title>Momentum analysis</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item *ngFor="let pillar_data of this.technical_pillars_results_augmented" [class.blur]="!userHasPremium()">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">{{pillar_data.short_description}}</div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <app-simple-progress-bar
                                    [color]="pillar_data.score_color" 
                                    [percentage]="pillar_data.score_percentage">
                                    </app-simple-progress-bar>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h3 style="font-weight: 500;">Technical Analysis result</h3>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <app-simple-progress-bar
                            [color]="tapScoreState.global_score_color" 
                            [percentage]="tapScoreState.global_score_percentage">
                            </app-simple-progress-bar>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            
            <mat-card class="my-2">
                <mat-card-header>
                    <mat-card-title>Company Price Overview</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <app-timeplot
                    [class.blur]="!userHasPremium()" 
                    [chart_title]="['Stock Price', 'SMA ST', 'SMA LT']" 
                    [time_values]="this.priceData.labels" 
                    [values]="[
                        this.priceData.values,
                        this.technical_data_for_pillars?.smaShortTermData,
                        this.technical_data_for_pillars?.smaLongTermData]" 
                    [show_points]="false"></app-timeplot>
                </mat-card-content>
            </mat-card>

            <mat-card class="my-2">
                <mat-card-header>
                    <mat-card-title>Relative Strengh Index</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <app-timeplot
                    [class.blur]="!userHasPremium()" 
                    [chart_title]="['RSI']" 
                    [time_values]="this.priceData.labels" 
                    [values]="[
                        this.technical_data_for_pillars?.rsiData]" 
                    [show_points]="true"></app-timeplot>
                </mat-card-content>
            </mat-card>
        </div>

    </div>
</div>


<section>
    <br>1. Sector and Industry Trends
    <br>Condition 1.1: Is the sector growth rate (revenue or market size) > X% over the last Y years?
    <br>Condition 1.2: Is the company’s sector experiencing favorable macroeconomic or geopolitical trends?
    <br>Condition 1.3: Is the company’s sector one of: Technology, Defense, Renewable Energy, Fintech, Healthcare, or Consumer Services?
    <br>2. Growth and Earnings Momentum
    <br>Condition 2.1: Is the company's revenue growth rate > X% over the last Y quarters?
    <br>Condition 2.2: Is the company's EPS increasing for at least 2 consecutive years?
    <br>Condition 2.3: Has the company's net income increased by > X% over the past year?
    <br>Condition 2.4: Is the company's Return on Equity (ROE) > X% and increasing for the last Y quarters?
    <br>Condition 2.5: Are earnings estimates for the next quarter higher than the previous quarter’s results?
    <br>3. Technological Innovation
    <br>Condition 3.1: Does the company have at least one announced R&D project or new technological innovation in the last X months?
    <br>Condition 3.2: Is the company deploying new technology that significantly reduces operational costs or enhances product offerings?
    <br>Condition 3.3: Has the company filed for or received a patent in the last X months?
    <br>4. Global Exposure
    <br>Condition 4.1: Does the company derive > X% of its revenue from international markets?
    <br>Condition 4.2: Is the company operating in one or more high-growth regions (China, Southeast Asia, etc.)?
    <br>Condition 4.3: Does the company benefit from a favorable international regulatory or trade environment (e.g., tariffs, trade deals)?
    <br>5. Market Sentiment and Speculative Interest
    <br>Condition 5.1: Has the stock’s price increased by > X% in the last Y days?
    <br>Condition 5.2: Has the average daily volume increased by > X% over the last Y days?
    <br>Condition 5.3: Are there more than X news articles or financial analyst reports mentioning the company over the past week?
    <br>Condition 5.4: Are there significant price target upgrades by at least Y% from major financial analysts?
    <br>Condition 5.5: Is the stock mentioned frequently on financial media or social media platforms in the last X days?
    <br>Condition 5.6: Has short interest in the stock dropped by > X% in the past Y weeks, indicating a short squeeze?
    <br>6. Valuation and Future Growth Potential
    <br>Condition 6.1: Is the forward P/E ratio of the company > X but growth rate (CAGR) is greater than Y%?
    <br>Condition 6.2: Is the company’s Price-to-Sales (P/S) ratio higher than the industry average by more than X%?
    <br>Condition 6.3: Has the company increased its capital expenditures (CAPEX) or R&D investment by > X% over the last Y quarters?
    <br>7. Momentum Indicators (Technical Analysis)
    <br>Condition 7.1: Is the stock price above its 50-day and 200-day moving averages?
    <br>Condition 7.2: Is the MACD line trending upwards and above the signal line for the last X days?
    <br>Condition 7.3: Is the Relative Strength Index (RSI) > 70, indicating overbought but momentum-driven conditions?
    <br>Condition 7.4: Has the stock's price-to-volume ratio increased by X% over the last Y trading days?
    <br>8. Institutional Ownership and Insider Activity
    <br>Condition 8.1: Has institutional ownership increased by > X% in the last Y months?
    <br>Condition 8.2: Have insiders purchased more than X shares in the last Y months?
    <br>Condition 8.3: Is there an increasing number of filings (13F) showing increased stakes by hedge funds or mutual funds in the last Y months?
    <br>9. Competitive Position and Market Share
    <br>Condition 9.1: Has the company's market share in its industry grown by > X% over the last Y quarters?
    <br>Condition 9.2: Is the company outperforming its top competitors in terms of revenue growth and profitability over the past Y quarters?
    <br>10. External Catalysts
    <br>Condition 10.1: Is there a major product launch, earnings report, or regulatory decision scheduled in the next X days that could positively affect the stock price?
    <br>Condition 10.2: Is the company involved in an industry trend with long-term growth potential (e.g., green energy, digitization)?
    <br>Condition 10.3: Have there been recent mergers, acquisitions, or strategic partnerships that strengthen the company's position?

</section>

