import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PillarData, PillarsService } from '../core/pillars.service';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationService } from '../navigation.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { LoginService } from '../core/login.service';
import { UserService } from '../core/user.service';
import { StripeService } from '../core/stripe.service';
import { FreeTrialService } from '../core/free-trial.service';
import { BlogArticleData, BlogService } from '../core/blog.service';
import { arrayRange, convert_ext_to_int_symbol, getColorFromScore, getLatestFidFromAllData, leastSquares1d } from '../core/helpers';
import { AdName } from '../components/advertise/advertise.component';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { computeAllTechnicalPillars, computeTechnicalData, extractTechnicalSentiment, TechnicalDataForPillars } from '../components/technical-pillars-list/technical_pillars';
import { FinancialData } from '../core/common_types';

@Component({
  selector: 'app-momentum-page',
  templateUrl: './momentum-page.component.html',
  styleUrls: ['./momentum-page.component.scss']
})
export class MomentumPageComponent {
  isLoading: boolean = false
  _isLoggedIn: boolean = false
  isSmallScreen: boolean = false
  noDataFound = true

  companySymbol: string|undefined = undefined
  companyName: string|undefined = undefined
  companyDescription: string|undefined = undefined
  companySector: string | undefined
  companyIndustry: string|undefined
  
  textDescription: string = "-"

  priceData: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}

  favoriteList: Array<string> = []
  hasValidSubscription: boolean|null = null
  hasValidSubscriptionObservable: Subscription|undefined
  freeTrial: boolean
  suggestedSymbol: string = ""
  stockPriceShortTermTrend: number = 0
  latestStockPrice: number = 0

  technical_data_for_pillars: TechnicalDataForPillars|null = null
  technical_pillars_results: Array<PillarData> = []
  technical_pillars_results_augmented: Array<PillarData & {score_percentage: number, score_color: string}> = []
  tapScoreState: {
    sentiment: 'buy'|'no_buy'|'no_decision',
    global_score: number,
    global_score_percentage: number,
    global_score_color: string,
  } = { sentiment: 'no_decision', global_score: -1, global_score_percentage: 0, global_score_color: 'black'}


  latestBlogArticle: BlogArticleData|undefined = undefined

  AdName = AdName

  getColorFromScore = getColorFromScore



  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pillarsService: PillarsService,
    private title: Title,
    private meta: Meta,
    private navigation: NavigationService,
    private breakpointObserver: BreakpointObserver,
    private loginService: LoginService,
    private userService: UserService,
    private el: ElementRef,
    private stripeService: StripeService,
    private freeTrialService: FreeTrialService,
    private blogService: BlogService
  ){
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe(result => {
          this.isSmallScreen = result.matches;
        });
      this.freeTrial = this.freeTrialService.isFreeTrialAvailable()
      this.title.setTitle('NeoTradr Momentum')
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('queryParams: ', params)
      const debugMode = params['debugMode']
      if(debugMode && debugMode == 'enable_debug_123098'){
        console.log('enabling debug mode')
        environment.production = false
      } 
    })
    
    this.activatedRoute.paramMap.subscribe(param => {
      const tempSymbol = param.get('symbol')
      if(tempSymbol){
        this.companySymbol = convert_ext_to_int_symbol(tempSymbol)
        this.title.setTitle(`NeoTradr - ${this.companySymbol}`)
        this.meta.updateTag({property: 'og:title', content: `NeoTradr - ${this.companySymbol}`})
        this.meta.updateTag({property: 'og:site_name', content: `NeoTradr - ${this.companySymbol}`})
        this.meta.updateTag({property: 'og:description', content: `Company pillars analysis details for ${this.companySymbol}`})
        
        this.loadSymbol()
      } else {
        this.router.navigate(['/'])
      }
    })

    // this.loginService.authState$.subscribe(user => {})
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      console.log('User isLoggedIn ?:', isLoggedIn)
      this._isLoggedIn = isLoggedIn
    })

    const tempSymbol = this.activatedRoute.snapshot.paramMap.get('symbol')
    if(tempSymbol){
      this.companySymbol = convert_ext_to_int_symbol(tempSymbol)
      // this.loadSymbol()
    } else {
      this.router.navigate(['/'])
    }
    this.refreshActiveSubscription()
    this.refreshFavoriteList()
  }

  loadSymbol(): void {
    if(this.isLoading){
      console.log('Skipping symbol loading because it is already doing it')
      return
    }
    this.isLoading = true
    this.noDataFound = false
    this.freeTrial = this.freeTrialService.isFreeTrialAvailable()
    //TODO: Call other backend services to get data
    this.pillarsService.getAllSymbolData(this.companySymbol!).subscribe(allSymbolData => {
      if(allSymbolData){
        this.companySymbol = allSymbolData.symbol
        this.companyName = this.pillarsService.getCompanyName(allSymbolData)
        this.companyDescription = this.pillarsService.getCompanyDescription(allSymbolData)
        this.companySector = this.pillarsService.getCompanySector(allSymbolData)
        this.companyIndustry = this.pillarsService.getCompanyIndustry(allSymbolData)
        console.log(`Loading data for ${this.companySymbol} (${this.companyName})`)  


        // const technical_data_for_pillars = computeTechnicalData(this.priceData.values)
        // const technical_pillars_results = computeAllTechnicalPillars(technical_data_for_pillars!)
        // this.tapScoreState.sentiment = extractTechnicalSentiment(technical_pillars_results).sentiment

        
        // Extraction of displayed data
        this.priceData = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.STOCK_PRICE)
        
        const [growth, b] = leastSquares1d(arrayRange(0, this.priceData.values.length, 1), this.priceData.values)
        this.stockPriceShortTermTrend = growth

        if(getLatestFidFromAllData(allSymbolData, FinancialData.ID.STOCK_PRICE) != null){
          this.latestStockPrice = getLatestFidFromAllData(allSymbolData, FinancialData.ID.STOCK_PRICE)!.value
        }
        
        const computeTrendOnLastNData = (data: Array<number>, n_data: number): number => {
          const last_n_data = data.slice(data.length - n_data, data.length)
          const [growth, b] = leastSquares1d(arrayRange(0, last_n_data.length, 1), last_n_data)
          return growth
        }
        
        this.blogService.getBlogArticleDataForSymbol(this.companySymbol).subscribe(blogArticles => {
          console.log('Blog articles:', blogArticles)
          
          let sortedBlogArticles = blogArticles.map(article => {
            return {...article, url: `${this.blogService.blog_origin_url}/${article.url}`}
          }).sort((a, b) => {
            return a.created_on < b.created_on ? 1 : -1
          })
          if(sortedBlogArticles.length > 0){
            this.latestBlogArticle = sortedBlogArticles[0]
          }          
        })

        this.computePillars()
      
      } else {
        this.companyName = "Unknown company"
        this.companySymbol = "No Symbol"
        this.companyDescription = "-"
        this.companySector = "-"
        this.companyIndustry = "-"
        this.latestBlogArticle = undefined
      }
      
      if(
        (this.companyName == undefined || this.companyName == "Unknown company" || this.companyName == "")
        ){
        // This are some conditions where there is a problem
        this.noDataFound = true
      }
      this.isLoading = false
    })
  }

  computePillars(): void {
    console.log('Computing technical pillars...')
    this.freeTrial = this.freeTrialService.isFreeTrialAvailable()
    this.technical_data_for_pillars = computeTechnicalData(this.priceData.values)
    this.technical_pillars_results = computeAllTechnicalPillars(this.technical_data_for_pillars!)
    this.technical_pillars_results_augmented = this.technical_pillars_results.map(x => {
      const percentage = 100*(x.weight - x.weight_min_value)/(x.weight_max_value-x.weight_min_value)
      return {
        ...x,
        score_percentage: percentage,
        score_color: percentage > 66 ? 'green' : percentage < 33 ? 'red' : 'orange'
      }
    })
    this.tapScoreState = extractTechnicalSentiment(this.technical_pillars_results)
  }

  back(): void {
    this.navigation.back()
  }

  scrollToSection(name: string) {
    const element = this.el.nativeElement.querySelector('#'+name);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  isFavorite(): boolean {
    if(!this.favoriteList){
      return false
    }
    return this.companySymbol ? this.favoriteList.includes(this.companySymbol) : false
  }

  async swapFavorite() {
    if(!this.companySymbol){
      return 
    }
    const idToken = await this.loginService.getIdToken()
    this.userService.setFavoriteStatus(idToken, this.companySymbol, !this.isFavorite()).subscribe(result => {
      console.log("Favorite update result: ", result)
      //Check error here and display message if error 
      if(result){
        this.refreshFavoriteList()
      }
    })
  }

  async refreshFavoriteList() {
    const idToken = await this.loginService.getIdToken()
    this.userService.getFavoriteList(idToken).subscribe(favoriteList => {
      console.log('Favorite list: ', favoriteList)
      if(favoriteList != null){
        this.favoriteList = favoriteList
      }
    })
  }

  async refreshActiveSubscription() {
    const idToken = await this.loginService.getIdToken()
    this.hasValidSubscriptionObservable = this.stripeService.hasValidSubscription(idToken).subscribe(hasValid => {
      this.hasValidSubscription = hasValid
      console.log('HasValidSubscription:', hasValid)
    })
  }

  userIsLogged(): boolean {
    /**
     * Return true is user is logged in or Free trial otherwise false
     */
    if(this._isLoggedIn){
      return true
    }
    if(this.freeTrial){
      return true
    }
    return false
  }
  userHasPremium(): boolean {
    /**
     * Return true is user has access through Premium or Free trial otherwise false
     */
    if(this._isLoggedIn && this.hasValidSubscription){
      return true
    }
    if(this.freeTrial){
      return true
    }
    return false
  }

  goToComparison(): void {
    this.router.navigate(['/compare'],{queryParams: {company1: this.companySymbol}})
  }

  ngOnDestroy(): void {
    console.log('Destroying CompanyPage')
  }

}
