<div *ngIf="countdownVisible" class="top-banner">{{countdownString}}  <app-login color="accent">Sign up</app-login></div>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="navbar" [ngClass]="{'collapsed': isCollapsed}">
      <button mat-icon-button (click)="toggleCollapse()" *ngIf="isSmallScreen">
        <mat-icon>menu</mat-icon>
      </button>
      <span>
        <a class="link-style white-text" href="/">
          <img *ngIf="!isSmallScreen" src="../assets/logo.png" alt="Profile picture" class="logoBadge" loading="lazy">
          NeoTradr
        </a>
      </span>
      <span flex></span>
      <div class="toolbar-menu">
        <app-login-required *ngIf="!isSmallScreen" [isLoggedIn]="isLoggedIn">
          <a class="m-2" mat-raised-button color="primary" href="/dashboard" 
            [disabled]="this.isLoggedIn ? false : true" style="color: white;">
            <mat-icon>dashboard</mat-icon>
            <span class="aligned-with-icon">Dashboard</span>
          </a>
        </app-login-required>
        <a class="m-2" mat-raised-button color="primary" href="/discover" *ngIf="!isSmallScreen">
          <mat-icon>stars</mat-icon>
          <span class="aligned-with-icon">Best ranked companies</span>
        </a>
        <a 
          class="m-2" 
          mat-raised-button
          [matMenuTriggerFor]="discoverMenu"
          color="primary"
          *ngIf="!isSmallScreen">
          <mat-icon> call_split</mat-icon>
          Discover
        </a>
      </div>
      <mat-menu #discoverMenu="matMenu">
        <a *ngFor="let link of links" mat-menu-item [href]="'/'+link.page" (click)="goTo(link.page)">
          <mat-icon *ngIf="link.icon">{{link.icon}}</mat-icon>
          {{link.name}}
        </a>
      </mat-menu>

      <span flex></span>
      <app-go-premium-button color="accent" *ngIf="this.isLoggedIn && !this.hasValidSubscription">
        <fa-icon matTooltip="Join Premium" class="crown-icon" [icon]="['fas', 'crown']"></fa-icon>
      </app-go-premium-button>
      <div *ngIf="!this.ntUser" class="userIcon">
        <app-login class="userIcon" mat-raised-button color="accent">
          Login or Sign up
        </app-login>
      </div>
      <div *ngIf="this.ntUser" class="userIcon">
        <button mat-icon-button [matMenuTriggerFor]="profileMenu">
          <mat-icon *ngIf="!this.ntUser.picture">account_circle</mat-icon>
          <img *ngIf="this.ntUser.picture" [src]="this.ntUser.picture" alt="Profile picture" class="userBadge" loading="lazy">
        </button>
        <mat-menu #profileMenu="matMenu">
          <a mat-menu-item href="/user">
            <mat-icon>account_circle</mat-icon>
            <span>Profile</span>
          </a>
          
          <a mat-menu-item href="/dashboard">
            <mat-icon>dashboard</mat-icon>
            <span>Dashboard</span>
          </a>

          <a mat-menu-item href="/compare">
            <mat-icon>compare_arrows</mat-icon>
            <span>Comparison Tool</span>
          </a>

          <a mat-menu-item href="/news">
            <mat-icon>article</mat-icon>
            <span>Business news</span>
          </a>

          <hr>
          <button mat-menu-item (click)="this.logout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <!-- This is the side view -->
    <mat-sidenav-container class="content">
      <mat-sidenav #sidenav mode="over" [opened]="!isCollapsed">
        <mat-nav-list>
          <div mat-menu-item>
            <h2 class="">Menu</h2>
          </div>
          <div *ngIf="!this.ntUser" class="userIcon" style="text-align: center;">
            <app-login class="userIcon" mat-raised-button color="accent">
              Login or Sign up
            </app-login>
          </div>
          <app-login-required [isLoggedIn]="isLoggedIn">
            <a mat-menu-item [disabled]="this.isLoggedIn ? false : true" href="/dashboard">
              <mat-icon>dashboard</mat-icon>
              <span>Dashboard</span>
            </a>
          </app-login-required>
          <a *ngFor="let link of links" mat-menu-item [href]="'/'+link.page" (click)="goTo(link.page)">
            <mat-icon *ngIf="link.icon">{{link.icon}}</mat-icon>
            {{link.name}}
          </a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <main id="main-content" #mainContent>
          <router-outlet></router-outlet>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>
</mat-sidenav-container>
