import { Component } from '@angular/core';
import { LoginService } from 'src/app/core/login.service';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-price-target-list',
  templateUrl: './price-target-list.component.html',
  styleUrls: ['./price-target-list.component.scss']
})
export class PriceTargetListComponent {
  isLoggedIn: boolean = false;
  priceTargets: Array<{price_target: number, symbol: string, timestamp: Date}> = [];
  dataSource: Array<{price_target: number, symbol: string, timestamp: Date}> = [];
  displayedColumns = ['symbol', 'price_target', 'timestamp', 'actions']

  constructor(private loginService: LoginService, private userService: UserService) { }

  ngOnInit(): void {
    this.isLoggedIn = this.loginService.isLoggedIn.value;
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if(this.isLoggedIn){
        this.getPriceTarget()
      }
    });
  }

  async getPriceTarget() {
    const idToken = await this.loginService.getIdToken();
    this.userService.getPriceTargets(idToken).subscribe(priceTargets => {
      this.priceTargets = priceTargets;
      this.dataSource = priceTargets;
      console.log('Price targets:', priceTargets);
    })
  }

  editPriceTarget(elementData: {price_target: number, symbol: string, timestamp: Date}) {
    console.log('Edit price target:', elementData);
  }

  async deletePriceTarget(elementData: {price_target: number, symbol: string, timestamp: Date}) {
    console.log('Delete price target:', elementData);
    const idToken = await this.loginService.getIdToken();
    this.userService.setPriceTarget(idToken, elementData.symbol, 0).subscribe(() => {
      this.getPriceTarget();
    })
  }
}
