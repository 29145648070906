<div class="header-sections">
    <mat-toolbar color="secondary" style="background-color: transparent;">
        <mat-toolbar-row>
            <button mat-button (click)="back()">
                <mat-icon>arrow_back_ios</mat-icon>
                Back
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <section>
        <div class="container">
            <div class="row py-0 my-0 justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <div class="mt-4">
                        <div class="mt-5">
                            <div class="nt-primary" style="font-family: Inter; font-size: 48px; font-weight: 800; line-height: 1.2;">
                                {{this.portfolioData?.portfolio_name}}
                            </div>
                        </div>
                        <div class="mt-2">
                            <div class="mb-1" style="font-family: Inter; font-size: 18px; color: rgb(107, 114, 128); line-height: 1.4;">
                                {{this.portfolioData?.description}}
                            </div>
                        </div>
                        <div class="mt-2">
                            <div class="mb-5" style="font-family: Inter; font-size: 14px; color: rgb(107, 114, 128); line-height: 1.4;">
                                Companies are ranked based on their score. 
                                The VIP score, which we refer to, reflects our evaluated assessment 
                                of the company's valuation derived from its pricing, balance sheets, 
                                cash-flow statement, income statement, and historical data. 
                                It is important to emphasize that this is solely a value indication 
                                and does not constitute a recommendation for initiating any transaction. 
                                In short, the higher the better.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>    
</div>

<section>
    <div class="container mt-5">
        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-xs-12">
                <div style="text-align: center;">
                    <div class="my-2" style="font-size: large;">Average Portfolio VIP Score</div>
                    <div style="width: 300px;" class="mx-auto">
                        <div>
                            <span style="font-size: x-large;">{{average_vip_score | number:'1.0-0'}}</span>/100
                        </div>
                    </div>
                </div>
                
                <hr>
                <div>
                    <div *ngIf="data_are_loading">
                        <mat-spinner></mat-spinner>
                        <h3>Data are loading...</h3>
                    </div>
                    <canvas #company_sector_chart id="company_sector_chart" style="position: relative; width: 100%;"></canvas>
                    <!-- <h3 *ngIf="!data_are_loading">{{this.text_company_ratio}}</h3> -->
                </div>
                <hr>
                <div>
                    <app-advertise></app-advertise>
                </div>
                
                <hr>
                <div>
                    <div *ngIf="data_are_loading">
                        <mat-spinner></mat-spinner>
                        <h3>Data are loading...</h3>
                    </div>
                    <canvas #company_ratio_chart id="company_ratio_chart" style="position: relative; width: 100%;"></canvas>
                    <h3 *ngIf="!data_are_loading">{{this.text_company_ratio}}</h3>
                </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-9 col-sm-12 col-xs-12">
                <div *ngIf="data_are_loading">
                    <mat-spinner></mat-spinner>
                    <h3>Data are loading...</h3>
                </div>
                <canvas #companies_result_chart id="companies_result_chart" style="position: relative; width: 100%;"></canvas>
            </div>
        </div>
    </div>
</section>

<section>
    <app-explore-more></app-explore-more>
</section>

<section>
    <app-footer></app-footer>
</section>

