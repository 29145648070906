import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import externalData from '../../../assets/company_name_data.json';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { convert_ext_to_int_symbol, convert_int_to_ext_symbol } from 'src/app/core/helpers';
import { searchButtonClicked } from 'src/app/core/analytics';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  data: Array<{symbol: string, company_name: string}> = externalData
  show_select: boolean = false

  @Output() input_text = new EventEmitter<string>()
  @Output() clicked = new EventEmitter<string>()

  
  _disabled: boolean = false
  @Input() 
  set disabled(value: boolean){
    this._disabled = value
  }
  get disabled(): boolean{
    return this._disabled
  }

  @Input() disableAction: boolean = false

  @ViewChild('singleSelect') singleSelect!: MatSelect;

  filtered_result: Array<{symbol: string, company_name: string}> = []
  search_list: Array<{symbol: string, company_name: string}> = []
  _text_value: string = "AAPL"

  @Input() 
  set symbol(value: string){
    if(value == this._text_value || value == undefined){
      return
    }
    value = convert_ext_to_int_symbol(value)
    this._text_value = value
    this.filtered_result = this.data.filter(x => {
      return x.symbol.toLowerCase().includes(value?.toLowerCase()) || x.company_name.toLowerCase().includes(value.toLowerCase())
    })
    this.search_list = this.filtered_result.slice(0, 5)
    if(value.length > 0){
      if(this.singleSelect){ // Done to manage SSR
        this.singleSelect.open()
      }
      this.show_select = true
    } else {
      if(this.singleSelect){
        this.singleSelect.close()
      }
      this.show_select = false
    }
  }
  get symbol(): string{
    return this._text_value
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onClick(): void {
    this.singleSelect.close()
    this.clicked.emit(this.symbol)
    if(!this.disableAction){
      this.goToCompanyPage(this.symbol) 
    }
  }

  onSelectionChange(event: any): void {
    this.symbol = event.value
    console.log('event:', event.value)
    if(!this.disableAction){
      this.router.navigate(['company', convert_int_to_ext_symbol(this.symbol)])
    }
  }

  goToCompanyPage(symbol: string): void {
    this.router.navigate(['company', convert_int_to_ext_symbol(symbol)])
  }

  onClickRandom(): void {
    const randomElement = this.data[Math.floor(Math.random() * this.data.length)];
    this.goToCompanyPage(randomElement.symbol)
  }

  goToComparison(): void {
    this.router.navigate(['/compare'],{queryParams: {company1: this.symbol}})
  }
}
