import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PillarData, PillarsService, AllSymbolData } from '../core/pillars.service';
import { DbData, FinancialData } from '../core/common_types';
import { getLocalStorageItem, setLocalStorageItem } from '../core/local-storage-manager';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { SymbolData } from '../components/symbol-list/symbol-list.component';
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { arrayRange, convert_ext_to_int_symbol, getColorFromScore, getFidFromAllData, getLatestFidFromAllData, leastSquares1d } from '../core/helpers';
import { NavigationService } from '../navigation.service';
import { formatCurrency, formatNumber, formatPercent } from '@angular/common';
import { LoginService } from '../core/login.service';
import { computeAllTechnicalPillars, computeTechnicalData, extractTechnicalSentiment } from '../components/technical-pillars-list/technical_pillars';
import { DetailedPillarCategories } from '../components/radar-view/detailed-radar-view/detailed-radar-view.component';
import { environment } from 'src/environments/environment';
import { UserService } from '../core/user.service';
import { StripeService } from '../core/stripe.service';
import { Observable, Subscription } from 'rxjs';
import { FreeTrialService } from '../core/free-trial.service';
import { MatTabGroup } from '@angular/material/tabs';
import { AdName } from '../components/advertise/advertise.component';
import { BlogArticleData, BlogService } from '../core/blog.service';

interface MetricData {
  name: string,
  value: string,
  unit?: string,
  trend?: number,
  style: string,
}

@Component({
  selector: 'app-company-page-simple',
  templateUrl: './company-page-simple.component.html',
  styleUrls: ['./company-page-simple.component.scss']
})
export class CompanyPageSimpleComponent {
  isLoading: boolean = false
  _isLoggedIn: boolean = false
  isSmallScreen: boolean = false
  noDataFound = true
  shortHightlights: boolean = true
  pillarsData: Array<PillarData> = []
  riskList: Array<string|null> = []
  metrics: Array<MetricData> = []
  vipScore: number|undefined = undefined
  companySymbol: string|undefined = undefined
  companyName: string|undefined = undefined
  companyDescription: string|undefined = undefined
  companySector: string | undefined
  companyIndustry: string|undefined
  categoriesSimple: {
    'valuation': Array<PillarData>, 'financial_performance': Array<PillarData>,
    'liquidity_debt': Array<PillarData>} = {valuation: [], financial_performance: [], liquidity_debt: []}
  categoriesDetailed: DetailedPillarCategories = {
      valuation: [],
      profitability_growth: [],
      financial_health: [],
      liquidity_debt: [],
      return_on_investment: []}
  tapScoreState: 'buy'|'no_buy'|'no_decision' = 'no_decision'
  textDescription: string = "-"

  priceData: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}
  perData: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}
  roicData: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}
  roeData: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}
  revenueData: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}
  netIncomeData: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}
  shareOutstanding: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}
  longTermLiabilities: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}
  freeCashflow: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}
  dividends: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}
  marketCap: {labels: Array<any>, values: Array<number>} = {labels: [], values: []}

  favoriteList: Array<string> = []
  hasValidSubscription: boolean|null = null
  hasValidSubscriptionObservable: Subscription|undefined
  freeTrial: boolean
  suggestedSymbol: string = ""
  latestShareOutstanding: number = 0
  latestFreeCashflow: number = 0
  latestPriceEarnings: number = 0
  latestPriceFreeCashflow: number = 0
  stockPriceShortTermTrend: number = 0
  latestStockPrice: number = 0
  latestNetIncome: number = 0

  latestBlogArticle: BlogArticleData|undefined = undefined

  AdName = AdName

  getColorFromScore = getColorFromScore

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pillarsService: PillarsService,
    private title: Title,
    private meta: Meta,
    private navigation: NavigationService,
    private breakpointObserver: BreakpointObserver,
    private loginService: LoginService,
    private userService: UserService,
    private el: ElementRef,
    private stripeService: StripeService,
    private freeTrialService: FreeTrialService,
    private blogService: BlogService) {
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe(result => {
          this.isSmallScreen = result.matches;
        });
      this.freeTrial = this.freeTrialService.isFreeTrialAvailable()
      this.title.setTitle('NeoTradr Analysis')
    }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('queryParams: ', params)
      const debugMode = params['debugMode']
      if(debugMode && debugMode == 'enable_debug_123098'){
        console.log('enabling debug mode')
        environment.production = false
      } 
    })
    
    this.activatedRoute.paramMap.subscribe(param => {
      const tempSymbol = param.get('symbol')
      if(tempSymbol){
        this.companySymbol = convert_ext_to_int_symbol(tempSymbol)
        this.title.setTitle(`NeoTradr - ${this.companySymbol}`)
        this.meta.updateTag({property: 'og:title', content: `NeoTradr - ${this.companySymbol}`})
        this.meta.updateTag({property: 'og:site_name', content: `NeoTradr - ${this.companySymbol}`})
        this.meta.updateTag({property: 'og:description', content: `Company pillars analysis details for ${this.companySymbol}`})
        
        this.loadSymbol()
      } else {
        this.router.navigate(['/'])
      }
    })

    // this.loginService.authState$.subscribe(user => {})
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      console.log('User isLoggedIn ?:', isLoggedIn)
      this._isLoggedIn = isLoggedIn
    })

    const tempSymbol = this.activatedRoute.snapshot.paramMap.get('symbol')
    if(tempSymbol){
      this.companySymbol = convert_ext_to_int_symbol(tempSymbol)
      this.loadSymbol()
    } else {
      this.router.navigate(['/'])
    }
    this.refreshActiveSubscription()
    this.refreshFavoriteList()
  }

  loadSymbol(): void {
    if(this.isLoading){
      console.log('Skipping symbol loading because it is already doing it')
      return
    }
    this.isLoading = true
    this.noDataFound = false
    this.freeTrial = this.freeTrialService.isFreeTrialAvailable()
    this.pillarsService.getAllSymbolData(this.companySymbol!).subscribe(allSymbolData => {
      if(allSymbolData){
        this.companySymbol = allSymbolData.symbol
        this.companyName = this.pillarsService.getCompanyName(allSymbolData)
        this.companyDescription = this.pillarsService.getCompanyDescription(allSymbolData)
        this.companySector = this.pillarsService.getCompanySector(allSymbolData)
        this.companyIndustry = this.pillarsService.getCompanyIndustry(allSymbolData)
        console.log(`Loading data for ${this.companySymbol} (${this.companyName})`)  

        const resP1 = this.pillarsService.computePillar1(allSymbolData)
        const resP2 = this.pillarsService.computePillar2(allSymbolData)
        const resP3 = this.pillarsService.computePillar3(allSymbolData)
        const resP4 = this.pillarsService.computePillar4(allSymbolData)
        const resP5 = this.pillarsService.computePillar5(allSymbolData)
        const resP6 = this.pillarsService.computePillar6(allSymbolData)
        const resP7 = this.pillarsService.computePillar7(allSymbolData)
        const resP8 = this.pillarsService.computePillar8(allSymbolData)
        const resP9 = this.pillarsService.computePillar9(allSymbolData)
        const resP10 = this.pillarsService.computePillar10(allSymbolData)
        const resP11 = this.pillarsService.computePillar11(allSymbolData)
        const resP12 = this.pillarsService.computePillar12(allSymbolData)
        const resP13 = this.pillarsService.computePillar13(allSymbolData)
        const resP14 = this.pillarsService.computePillar14(allSymbolData)
        const resP15 = this.pillarsService.computePillar15(allSymbolData)
        const respP16 = this.pillarsService.computePillar16(allSymbolData)
        const respP17 = this.pillarsService.computePillar17(allSymbolData)
        const respP18 = this.pillarsService.computePillar18(allSymbolData)
        
        this.pillarsData = [
          resP1, resP2, resP3, resP4, resP5, resP6, resP7, resP8,
          resP9, resP10, resP11, resP12, resP13, resP14, resP15,
          respP16, respP17, respP18]
        this.vipScore = this.pillarsService.computeVipScore(this.pillarsData)

        const technical_data_for_pillars = computeTechnicalData(this.priceData.values)
        const technical_pillars_results = computeAllTechnicalPillars(technical_data_for_pillars!)
        this.tapScoreState = extractTechnicalSentiment(technical_pillars_results).sentiment

        // Used for highlight section
        // const riskyPillars = getRiskyPillarsSorted(this.pillarsData)
        // this.riskList = riskyPillars.map(x => getTextRiskAssessmentForPillar(x, false))

        this.categoriesSimple.valuation = [resP1, resP8, resP9, resP14]
        this.categoriesSimple.financial_performance = [resP2, resP3, resP4, resP5, resP7, resP11, resP15, respP17, respP18]
        this.categoriesSimple.liquidity_debt = [resP6, resP10, resP12, resP13, respP16]

        // This is done this way to trigger Angular change detection
        this.categoriesDetailed = {
          valuation: [resP1, resP12, resP14, respP16, resP8],
          profitability_growth: [resP2, resP3, resP4, resP7, resP9, resP11],
          financial_health: [resP6, resP10, resP15],
          liquidity_debt: [resP15, respP18, resP10, resP6],
          return_on_investment: [respP17, resP13, resP5]
        }
        // Extraction of displayed data
        this.priceData = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.STOCK_PRICE)
        this.perData = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.PER)
        this.roicData = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.ROIC)
        this.roeData = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.RETURN_ON_EQUITY)
        this.revenueData = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.REVENUE)
        this.netIncomeData = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.NET_INCOME)
        this.shareOutstanding = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.NUMBER_SHARES)
        this.longTermLiabilities = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.TOTAL_NON_CURRENT_LIABILITIES)
        this.freeCashflow = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.FREE_CASHFLOW)
        this.dividends = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.DIVIDEND)
        this.marketCap = this.pillarsService._getChartJsTimeDataFromFid(allSymbolData, FinancialData.ID.MARKET_CAPITALIZATION)

        // Computing metrics 
        this.metrics = []
        const addMetricIfAvailable = (m_name: string, unit: 'percent'|'currency'|'number', fid: string, trend?: number, style?: string, valueTransformationClbk?: ((val: number)=>number)) => {
          const latestData = getLatestFidFromAllData(allSymbolData, fid)
          if(latestData){
            let val = Math.round(latestData.value*100)/100
            if(style == undefined && trend != undefined){
              if(trend > 0.4){
                style = "#2ECC71"
              } else if(trend < -0.4){
                style = "#E74C3C"
              } else {
                style = "#F1C40F"
              }
            }
            if(style == undefined){
              style = ''
            }
            if(valueTransformationClbk){
              val = valueTransformationClbk(val)
            }
            if(unit == 'percent'){
              this.metrics.push({name: m_name, value: `${formatPercent(val, this.pillarsService.locale)}`, unit: unit, trend: trend, style: style})
            } else if(unit == 'currency'){
              this.metrics.push({name: m_name, value: `${formatCurrency(val, this.pillarsService.locale, '$')}`, unit: unit, trend: trend, style: style})
            } else {
              this.metrics.push({name: m_name, value: `${formatNumber(val, this.pillarsService.locale)}`, unit: unit, trend: trend, style: style})
            }
          } else {
            console.log(`Error could not find latest data for: ${fid}`)
          }
        }
        
        const [growth, b] = leastSquares1d(arrayRange(0, this.priceData.values.length, 1), this.priceData.values)
        this.stockPriceShortTermTrend = growth

        // Getting latest data used multiple times
        if(getLatestFidFromAllData(allSymbolData, FinancialData.ID.NUMBER_SHARES) != null){
          this.latestShareOutstanding = getLatestFidFromAllData(allSymbolData, FinancialData.ID.NUMBER_SHARES)!.value
        }
        if(getLatestFidFromAllData(allSymbolData, FinancialData.ID.FREE_CASHFLOW) != null){
          this.latestFreeCashflow = getLatestFidFromAllData(allSymbolData, FinancialData.ID.FREE_CASHFLOW)!.value
        }
        if(getLatestFidFromAllData(allSymbolData, FinancialData.ID.PER) != null){
          this.latestPriceEarnings = getLatestFidFromAllData(allSymbolData, FinancialData.ID.PER)!.value
        }
        if(
          getLatestFidFromAllData(allSymbolData, FinancialData.ID.FREE_CASHFLOW) != null && 
          getLatestFidFromAllData(allSymbolData, FinancialData.ID.MARKET_CAPITALIZATION) != null){
          this.latestPriceFreeCashflow = getLatestFidFromAllData(allSymbolData, FinancialData.ID.MARKET_CAPITALIZATION)!.value / getLatestFidFromAllData(allSymbolData, FinancialData.ID.FREE_CASHFLOW)!.value
        }
        if(getLatestFidFromAllData(allSymbolData, FinancialData.ID.STOCK_PRICE) != null){
          this.latestStockPrice = getLatestFidFromAllData(allSymbolData, FinancialData.ID.STOCK_PRICE)!.value
        }
        if(getLatestFidFromAllData(allSymbolData, FinancialData.ID.NET_INCOME) != null){
          this.latestNetIncome = getLatestFidFromAllData(allSymbolData, FinancialData.ID.NET_INCOME)!.value
        }

        const computeTrendOnLastNData = (data: Array<number>, n_data: number): number => {
          const last_n_data = data.slice(data.length - n_data, data.length)
          const [growth, b] = leastSquares1d(arrayRange(0, last_n_data.length, 1), last_n_data)
          return growth
        }
          

        addMetricIfAvailable('Stock price', 'currency', FinancialData.ID.STOCK_PRICE, this.stockPriceShortTermTrend)
        addMetricIfAvailable('Price/Earnings (PER)', 'number', FinancialData.ID.PER)
        addMetricIfAvailable('Price/Book (P/B)', 'number', FinancialData.ID.PRICE_TO_BOOK_RATIO)
        addMetricIfAvailable('ROIC', 'percent', FinancialData.ID.ROIC, computeTrendOnLastNData(this.roicData.values, 2))
        addMetricIfAvailable('ROE', 'percent', FinancialData.ID.RETURN_ON_EQUITY, computeTrendOnLastNData(this.roeData.values, 2))
        addMetricIfAvailable('Market Cap', 'currency', FinancialData.ID.MARKET_CAPITALIZATION, computeTrendOnLastNData(this.marketCap.values, 2))

        this.textDescription = this.pillarsService.getTextDescription(
          allSymbolData, 
          this.pillarsData, 
          technical_pillars_results,
          this.tapScoreState)

        
        this.blogService.getBlogArticleDataForSymbol(this.companySymbol).subscribe(blogArticles => {
          console.log('Blog articles:', blogArticles)
          
          let sortedBlogArticles = blogArticles.map(article => {
            return {...article, url: `${this.blogService.blog_origin_url}/${article.url}`}
          }).sort((a, b) => {
            return a.created_on < b.created_on ? 1 : -1
          })
          if(sortedBlogArticles.length > 0){
            this.latestBlogArticle = sortedBlogArticles[0]
          }
          
        })
      
      } else {
        this.companyName = "Unknown company"
        this.companySymbol = "No Symbol"
        this.vipScore = 0
        this.companyDescription = "-"
        this.companySector = "-"
        this.companyIndustry = "-"
        this.pillarsData = []
        this.latestBlogArticle = undefined
      }
      
      if(
        (this.vipScore == undefined || this.vipScore == 0) &&
        (this.companyName == undefined || this.companyName == "Unknown company" || this.companyName == "")
        ){
        // This are some conditions where there is a problem
        this.noDataFound = true
      }
      this.isLoading = false
    })
  }

  back(): void {
    this.navigation.back()
  }

  scrollToSection(name: string) {
    const element = this.el.nativeElement.querySelector('#'+name);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  isFavorite(): boolean {
    if(!this.favoriteList){
      return false
    }
    return this.companySymbol ? this.favoriteList.includes(this.companySymbol) : false
  }

  async swapFavorite() {
    if(!this.companySymbol){
      return 
    }
    const idToken = await this.loginService.getIdToken()
    this.userService.setFavoriteStatus(idToken, this.companySymbol, !this.isFavorite()).subscribe(result => {
      console.log("Favorite update result: ", result)
      //Check error here and display message if error 
      if(result){
        this.refreshFavoriteList()
      }
    })
  }

  async refreshFavoriteList() {
    const idToken = await this.loginService.getIdToken()
    this.userService.getFavoriteList(idToken).subscribe(favoriteList => {
      console.log('Favorite list: ', favoriteList)
      if(favoriteList != null){
        this.favoriteList = favoriteList
      }
    })
  }

  async refreshActiveSubscription() {
    const idToken = await this.loginService.getIdToken()
    this.hasValidSubscriptionObservable = this.stripeService.hasValidSubscription(idToken).subscribe(hasValid => {
      this.hasValidSubscription = hasValid
      console.log('HasValidSubscription:', hasValid)
    })
  }

  ngOnDestroy(): void {
    console.log('Destroying CompanyPage')
  }

  userIsLogged(): boolean {
    /**
     * Return true is user is logged in or Free trial otherwise false
     */
    if(this._isLoggedIn){
      return true
    }
    if(this.freeTrial){
      return true
    }
    return false
  }
  userHasPremium(): boolean {
    /**
     * Return true is user has access through Premium or Free trial otherwise false
     */
    if(this._isLoggedIn && this.hasValidSubscription){
      return true
    }
    if(this.freeTrial){
      return true
    }
    return false
  }

  goToComparison(): void {
    this.router.navigate(['/compare'],{queryParams: {company1: this.companySymbol}})
  }

  async suggestCompany() {
    console.log('Suggesting company:', this.suggestedSymbol)
    if(this.suggestedSymbol){
      const idToken = await this.loginService.getIdToken()
      this.userService.suggestCompany(idToken, this.suggestedSymbol).subscribe(result => {
        console.log('Suggest company result:', result)
      })
    }
  }
}
