<div class="header-sections pb-2">
    <mat-toolbar color="secondary" style="background-color: transparent;">
        <mat-toolbar-row>
            <button mat-button (click)="back()">
                <mat-icon>arrow_back_ios</mat-icon>
                Back
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    
    <section>
        <div class="container mt-4">
            <div class="row py-0 my-0 justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <app-search-bar></app-search-bar>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="row py-0 my-0 justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <div class="mt-2">
                        <div class="mt-5">
                            <div class="nt-primary" style="font-family: Inter; font-size: 48px; font-weight: 800; line-height: 1.2;">
                                Discover companies
                            </div>
                        </div>
                        <div class="mt-2">
                            <div class="mb-3" style="font-family: Inter; font-size: 18px; color: rgb(107, 114, 128); line-height: 1.4;">
                                Discover companies with the highest value score
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section>
        <div class="container">
            <div class="row py-0 my-0 justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <div class="mb-4" style="font-family: Inter; font-size: 14px; color: rgb(107, 114, 128); line-height: 1.4;">
                        Companies are ranked based on their score. 
                        The VIP score, which we refer to, reflects our evaluated assessment 
                        of the company's valuation derived from its pricing, balance sheets, 
                        cash-flow statement, income statement, and historical data. 
                        It is important to emphasize that this is solely a value indication 
                        and does not constitute a recommendation for initiating any transaction. 
                        In short, the higher the better.
                    </div>
                </div>
            </div>
        </div>
    </section>    
</div>

<section>
    <div class="container">
        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 my-2">
                <mat-tab-group>
                    <mat-tab label="All Companies">
                        <mat-card class="mat-elevation-z8">
                            <mat-card-header>
                                <mat-card-title>Highest VIP score</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <app-highest-score-ranking></app-highest-score-ranking>
                            </mat-card-content>
                        </mat-card>
                    </mat-tab>
                    <mat-tab label="By Sector">
                        <mat-card class="mat-elevation-z8">
                            <mat-card-header>
                                <mat-card-title>Highest VIP By Sector</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <app-highest-score-by-sector></app-highest-score-by-sector>
                            </mat-card-content>
                        </mat-card>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <!-- <div class="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-xs-12 my-2">
                <mat-accordion>
                    <mat-expansion-panel  [expanded]="!isSmallScreen">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="nt-primary" style="font-family: Inter; font-size: 28px; font-weight: 800; line-height: 1.2;">
                                    Business news
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-main-news></app-main-news>    
                    </mat-expansion-panel>
                </mat-accordion>
            </div> -->
        </div>
    </div>
</section>

<section>
    <app-explore-more></app-explore-more>
</section>

<section>
    <app-footer></app-footer>
</section>